<template>
  <button
    role="button"
    class="relative rounded-lg px-2 py-1.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex flex-col text-left"
    @click.stop="$emit('preview')"
  >
    <div class="flex flex-1 items-center justify-between gap-2 w-full">
      <div class="flex-shrink-0 font-medium flex items-center gap-2">
        <fw-icon-arrow-right class="w-5 h-5 text-gray-500" />
        <span class="text-gray-500">{{ item.year }}</span>
        <span v-if="item.month" class="font-semibold">{{ $t(`months.${item.month}`) }}</span>
        <span class="text-gray-500 text-xs">{{ getFileMediaType(item) }}</span>
      </div>

      <DownloadButton @download="$emit('download')" />
    </div>
  </button>
</template>

<script>
import DownloadButton from '@/fw-modules/fw-core-vue/ui/components/buttons/DownloadButton'

export default {
  name: 'RecordManageProcedure',
  components: {
    DownloadButton,
  },
  props: {
    item: Object,
  },
  methods: {
    getFileMediaType(file) {
      if (file.filename) return '.' + file.filename.slice(file.filename.lastIndexOf('.') + 1)

      return file.type
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "download": "Descarregar",
    "months": {
      "1": "Janeiro",
      "2": "Fevereiro",
      "3": "Março",
      "4": "Abril",
      "5": "Maio",
      "6": "Junho",
      "7": "Julho",
      "8": "Agosto",
      "9": "Setembro",
      "10": "Outubro",
      "11": "Novembro",
      "12": "Dezembro"
    }
  },
  "en": {
    "download": "Download",
    "months": {
      "1": "January",
      "2": "February",
      "3": "March",
      "4": "April",
      "5": "May",
      "6": "June",
      "7": "July",
      "8": "August",
      "9": "September",
      "10": "October",
      "11": "November",
      "12": "December"
    }
  }
}
</i18n>
